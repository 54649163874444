<!-- eslint-disable no-undef -->
<template>
  <div class="auth-wrapper">
    <b-row class="auth-inner">
      <!-- Left Text-->

      <b-col lg="6" class="d-none d-lg-flex align-items-center auth-bg">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex justify-content-center p-xl-4 align-items-center"
      >
        <b-col
          rounded
          class="col-8 text-center auth-bg px-xl-4 py-xl-5 rounded"
        >
          <b-card-title class="login-text-color py-2">
            Reset Password
          </b-card-title>
          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form
              class="auth-register-form py-5"
              @submit.prevent="changePassword"
            >
              <!-- <b-form-group
                label="Token"
                label-for="register-token"
                class="text-left label-text"
              >
                <validation-provider
                  #default="{ errors }"
                  name="token"
                  vid="token"
                  rules="required"
                >
                  <b-form-input
                    id="register-token"
                    v-model="form.token"
                    type="text"
                    name="register-token"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type password reset token"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- username -->
              <b-form-group
                label="New Password"
                label-for="register-password"
                class="text-left label-text"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-form-input
                    id="register-password"
                    v-model="form.password"
                    type="password"
                    name="register-password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type your new password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Re-ype New Password"
                label-for="register-confirmPassword"
                class="text-left label-text"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirmPassword"
                  vid="confirmPassword"
                  rules="required"
                >
                  <b-form-input
                    id="register-confirmPassword"
                    type="password"
                    v-model="form.password_confirmation"
                    name="register-confirmPassword"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type your new password again"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="d-flex justify-content-center mt-3">
                <b-button
                  type="submit"
                  :disabled="invalid"
                  class="btn btn-color rounded"
                  >Confirm Password</b-button
                >
              </b-form-group>
              <!-- <p class="text-center mt-2 text-muted">
                <span>or,</span>
              </p> -->
              <!-- <p class="text-center mt-2">
                <b-link :to="{ name: 'auth-login' }">
                  <span class="sign-in-color">&nbsp;Create A New Account</span>
                </b-link>
              </p> -->

              <!-- <p class="text-center mt-2">
                <span>Forgot password?</span>
              </p>
              <p class="text-center mt-2">
                <b-link :to="{ name: 'forgot-password' }">
                  <span class="sign-in-color"
                    >&nbsp;Click here to recover the password.</span
                  >
                </b-link>
              </p> -->
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index";
import { toast } from "@/utils/toast";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { email, required } from "@validations";
import {
BButton,
BCardText,
BCardTitle,
BCol,
BForm,
BFormCheckbox,
BFormGroup,
BFormInput,
BImg,
BInputGroup,
BInputGroupAppend,
BLink,
BRow
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        password: "",
        password_confirmation: "",
        token: "",
        email: "",
      },
      sideImg: require("@/assets/images/logo/logo-main.png"),
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-undef
        this.sideImg = require("@/assets/images/logo/logo-main.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    this.verifyOtp();
    // const token = this.$route.query;
    // axios.post('https://grypas.inflack.xyz/grypas-api/api/v1/verify-otp',token).then((response) => {

    //         console.log(response.data);
    //       })
    //       .catch((error) => {
    //        console.log(error)
    //       });

    // if (!this.$store.state.auth.passwordResetMail) {
    //   //this.$router.push("/forgot-password");
    // } else {
    //   this.form.email = this.$store.state.auth.passwordResetMail;

    // }
  },
  // mounted(){
  //   this.verifyOtp();
  // },
  methods: {
    changePassword() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          if (this.form.password !== this.form.password_confirmation) {
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              "Password and Confirm Password does not match"
            );
            return;
          } else {
            this.$store
              .dispatch("auth/changePassword", this.form)
              .then(() => {
                this.$router.push("/login");
              })
              .catch((error) => {
                toast(
                  "Validation Error",
                  "AlertTriangleIcon",
                  "danger",
                  error.response.data.message
                );
              });
          }
        }
      });
    },
    verifyOtp() {
      const token = this.$route.query;


      this.$store
        .dispatch("auth/verifyOTP", token)
        .then((response) => {
          
          this.form.token = this.$route.query.token;
          this.form.email = this.$route.query.email;
        })
        .catch((error) => {
          this.$router.push("/error-404");
          console.log("get error", error);
        });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.login-text-color {
  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 53px;
}
.sign-in-color {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #7190ef;
}
.btn-color {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}
.label-text {
  /* 20 regular */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;

  /* identical to box height */

  /* Text main */
  color: #515b60;
}

#register-confirmPassword,
#register-password,
#register-token {
  background: #f9f9f9;
  border-radius: 4px;
}
</style>
